/* #region | Google Inter variable font */
@font-face {
    font-family: 'Inter Flex';
    src: url('InterVariable.woff2') format('woff2-variations');
    src: url('InterVariable.woff2') format('woff2') tech('variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
/* Regular */
@font-face {
    font-family: 'Inter Flex Regular';
    src: url('InterVariable.woff2') format('woff2-variations');
    src: url('InterVariable.woff2') format('woff2') tech('variations');
    font-weight: 300;
}
/* Bold */
@font-face {
    font-family: 'Inter Flex Bold';
    src: url('InterVariable.woff2') format('woff2-variations');
    src: url('InterVariable.woff2') format('woff2') tech('variations');
    font-weight: 500;
}
/* --------------------------------------------------- */
/* #endregion */

/* #region | Avenir Next font */
/* @font-face {
    font-family: "PrimaryFont";
    src: local("AvenirNextLTProCn"),
        url("./AvenirNextLTPro-Cn.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontIalic";
    src: local("AvenirNextLTProDnIt"),
        url("./AvenirNextLTPro-CnIt.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontBold";
    src: local("AvenirNextLTProBold"),
        url("./AvenirNextLTPro-BoldCn.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontBoldItalic";
    src: local("AvenirNextLTProBoldIt"),
        url("./AvenirNextLTPro-BoldCnIt.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontMedium";
    src: local("AvenirNextLTProMedium"),
        url("./AvenirNextLTPro-MediumCn.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontMediumItalic";  
    src: local("AvenirNextLTProMediumIt"),
        url("./AvenirNextLTPro-MediumCnIt.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontDemi";   
    src: local("AvenirNextLTPrDemi"),
        url("./AvenirNextLTPro-DemiCn.otf") format("truetype");
}
@font-face {
    font-family: "PrimaryFontDemiItalic";  
    src: local("AvenirNextLTProMediumIt"),
        url("./AvenirNextLTPro-MediumCnIt.otf") format("truetype");
}
/* --------------------------------------------------- */
/* #endregion */

/* Fortis SSi font */
/* @font-face {
    font-family: "SecondaryFont"; 
    src: local("Fortis SSi"),
        url("./Fortis SSi.ttf") format("truetype");
} */

